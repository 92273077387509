$user-type: 'buyer';
$device-type: 'responsive';
@import '~dibs-sassy/exports/_colors';
@import '~dibs-sassy/exports/_fonts';
@import '~dibs-sassy/exports/_spacing';
@import '~dibs-sassy/exports/_zindex';

:local {
    @keyframes folder-slide {
        0%,
        100% {
            top: 0;
            left: 0;
        }
        50% {
            top: 0;
            left: -4px;
        }
    }

    @keyframes folder-open {
        0%,
        100% {
            opacity: 0;
        }
        40%,
        60% {
            opacity: 1;
            transform: skew(-15deg);
        }
    }

    .icon {
        @include sassy-z-index(bump);
        stroke-width: 12px;
        stroke: $sassy-color-buyer-primary;
        fill: transparent;
        transition: fill 0.2s ease;

        &.isIcon {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        &.isButton {
            height: 26px;
            width: 26px;
        }

        &.isAnimated,
        &.isFilled,
        &:hover {
            fill: $sassy-color-buyer-primary;
        }

        &.isAnimated {
            animation: folder-slide 0.7s ease-in-out;
        }

        &.isStrokeWidthHeavy {
            stroke-width: 18px;
        }

        &.dark {
            stroke: $sassy-color-noir;

            &.isAnimated,
            &.isFilled,
            &:hover {
                fill: $sassy-color-noir;
            }
        }

        &.light {
            stroke: $sassy-color-white;

            &.isAnimated,
            &.isFilled,
            &:hover {
                fill: $sassy-color-white;
            }
        }
    }

    .iconButton {
        @include sassy-font-body($size: small);
        align-items: center;
        background: $sassy-color-white;
        border: 2px solid $sassy-color-white;
        color: $sassy-color-noir;
        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        height: 40px;
        outline: none;
        padding: 7px;
        text-align: center;
        transition: all 175ms linear;
        vertical-align: top;
        width: auto;
        &:hover,
        &:hover > .icon {
            color: $sassy-color-buyer-primary;
            stroke: $sassy-color-buyer-primary;
        }
        &.disabled {
            opacity: 0.2;
            pointer-events: none;
        }
        & > .icon {
            transition: all 175ms linear;
            fill: $sassy-color-white;
            stroke: $sassy-color-noir;
            height: 100%;
        }
    }

    .text {
        line-height: 1;
        margin: auto;
        padding-left: $sassy-spacing-x-small;
    }

    .iconShadow {
        position: absolute;
        top: 4px;
        left: 4px;
        height: 70%;
        width: 80%;
        border-radius: 12%;
        background: $sassy-color-buyer-secondary;
        visibility: hidden;

        &.dark {
            background: $sassy-color-noir;
        }

        &.light {
            background: $sassy-color-white;
        }

        &.isAnimated {
            visibility: visible;
            animation: folder-open 0.7s ease-in-out;
        }
    }

    .iconWrapper {
        background: none;
        border: none;
        width: 100%;
        height: 100%;
        &.button {
            position: relative;
            @include sassy-background-opacity(white, high);
            padding: $sassy-spacing-x-small;
            width: 44px;
            height: 44px;
            transition: 0.5s transform ease;
            border-radius: 50%;
            &:active,
            &.isActive {
                transform: scale(0.93);
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                box-shadow: 0px 2px 4px 0px rgba(34, 34, 34, 0.15);
                transition: 0.5s opacity ease;
                border-radius: 50%;
            }
            &:active:after,
            &.isActive:after {
                opacity: 0;
            }
        }
    }

    .folder {
        @include sassy-z-index(bump);
        height: 100%;
        width: 100%;
        position: absolute;
        right: 0;
        top: 0;
        &:hover {
            cursor: pointer;
        }
        &.button {
            right: 50%;
            top: 50%;
            transform: translate3d(50%, -50%, 0);
            height: 23px;
            width: 23px;
        }
    }

    svg.icon:not(:root) {
        overflow: visible;
        padding: 1px;
    }
}
